.portrait {
	width: 300px;
	max-width: 90vw;
}

.modalContainer {
   img {
      max-height: 85vh;
		max-width: 90vw;
		width: auto;
      display: block;
      margin: auto;
   }
}