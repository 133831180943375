@import "./colors.scss";

$theme-colors: (
  "primary": $orange,
  "secondary": $white,
  "light": #f8f9fa,
  "success": $green,
  "danger": #dc3545,
);

@import "~bootstrap/scss/bootstrap";

html {
	scroll-padding-top: 86px;
}

html, body, #root {
	min-height: 100vh;
}

#root {
	display: flex;
	flex-direction: column;
}

.btn-primary {
	color: white;
}

.nav-link:hover {
	color: $orange !important;
}

textarea {
	font-size: 1.05rem !important;
	border: 1px solid $blue !important;
	background-color: rgba($blue, 0.1) !important;
}

img.clickable {
	cursor: pointer;
}