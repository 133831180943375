@import "../../styles/colors.scss";

.imageContainer {
	height: calc(80vh - 120px);
	width: auto;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.modalContainer {
   img {
		max-height: 85vh;
		max-width: 90vw;
		width: auto;
      display: block;
      margin: auto;
   }
}