@import "./fading.scss";

.homeContainer {
   display: grid;
   grid-template-columns: 1fr;

   div {
      grid-row-start: 1;
      grid-column-start: 1;
   }
}

.imageContainer {
   animation: fadeOut linear 5s;
   -webkit-animation: fadeOut linear 5s;
   -moz-animation: fadeOut linear 5s;
   -o-animation: fadeOut linear 5s;
   -ms-animation: fadeOut linear 5s;
   opacity: 0;
   z-index: -999;

   img {
      max-width: 100%;
      max-height: 100%;
   }
}

.startImageContainer {
   img {
      display:block;
      margin:auto;
      width: 50%;
      cursor: pointer;
   }
}

.modalContainer {
   img {
      max-height: 85vh;
		max-width: 90vw;
		width: auto;
      display: block;
      margin: auto;
   }
}
